<template>
  <div>
      <el-form>
         <el-form-item label="机构城市" >
             <el-cascader
            clearable
            v-model="queryCity"
            :options="cityoptions"
            :props="querycityProps"
            placeholder=""
          ></el-cascader>
           <el-checkbox v-model="parent_mec">主店</el-checkbox>
            </el-form-item>
       <el-form-item label="选择机构" required>
            <el-select
              v-model="mechanism_id"
              placeholder="请输入名称"
              prefix-icon="el-icon-search"
              filterable
              remote
              :remote-method="getMechanism"
              @change="changeMechanism"
            >
              <el-option
                v-for="(item, index) in mechanism_list"
                :key="index"
                :label="item.mechanism_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="选择课程" required>
          <el-select
            v-model="course"
            remote
            :remote-method="getCourse"
            clearable
            filterable
            @focus="getCourse"
            value-key="id"
            @change="changeCourse"
          >
            <el-option
              v-for="item in course_list"
              :key="item.id"
              :value="item"
              :label="item.title"
            >
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item  v-if="master_appointment.id" label="已排课程">
          {{master_appointment.start_time}} -
           {{master_appointment.end_time.split(' ')[1]}}
        </el-form-item>
        <el-form-item label="上课时间" required> 
          <el-time-select
            placeholder="起始时间"
            v-model="startTime"
            value-format="hh:mm:ss"
            :picker-options="{ start: '07:00', step: '00:05', end: '23:00' }"
          >
          </el-time-select>
          -
          <el-time-select
            placeholder="结束时间"
            v-model="endTime"
            :picker-options="{
              start: '07:00',
              step: '00:05',
              end: '23:00',
              minTime: startTime,
            }"
            value-format="hh:mm:ss"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="上课日期" required>
          <el-date-picker
            v-model="day"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="saveForm">确 定</el-button>
        </el-form-item>
      </el-form>

  </div>
</template>

<script>
export default {
 
  data() {
    return {
      dialogVisible: false,
      mechanism_id: "",
      course_list: [],
      course: {},
      startTime: "",
      endTime: "",
      day: "",
      master_appointment:{},
      mechanism_list:[],
       queryCity:[],
      cityoptions: JSON.parse(localStorage.getItem("addrList")),
      querycityProps: {
        checkStrictly: true,
        value: "district",
        label: "district",
        children: "childrens",
      },
       parent_mec:false
    };
  },

  mounted() {},

  methods: {
     getMechanism(query) {
      let url = "/user/mastermechanism/queryByMessage";
      if (typeof query == "string") {
        let data = {
              type: "teach_paypal",
              status: 2,
              pageSize: 30,
              currentPage: 1,
              mechanism_name: query,
            }
         if(this.queryCity.length > 0){
          data.province = this.queryCity[0] || null
          data.city = this.queryCity[1] || null
          data.district = this.queryCity[2] || null
        }
        if(this.parent_mec){
          data.parent_id = 0
        }
        this.$axios
          .get(url, {
            params: data
          })
          .then((res) => {
            // console.log(res.data);
            this.mechanism_list = res.data.data.rows;
          })
          .catch((rej) => {});
      }
    },
      changeMechanism(v) {
      this.getCourse();
    },
    changeCourse(v){

      if(v.master_appointment_id){
         this.$axios({
        url:'/user/masterAppointment/findById',
        params:{id:v.master_appointment_id} 
      }).then((res) => {
        this.master_appointment = res.data.data
      });
      }
    },
    saveForm() {
        if(!this.course.id){
            this.$message("请选择课程")
            return
        }
        if(!this.day || !this.startTime || !this.endTime){
            this.$message("请选择上课时间")
            return
        }

      let url = "/user/masterAppointment/insertCourseAppointment";
      let params = {
        master_set_price_id: this.course.id,
        start_time: this.day + " " + this.startTime + ":00",
        end_time: this.day + " " + this.endTime + ":00",
      };
      this.$axios({
        url,
        method: "post",
        data: params,
      }).then((res) => {
        this.$message("成功");
        this.dialogVisible = false;
      });
    },
    getCourse(query) {
      let url = "/user/masterSetPrice/queryByMessage";
      let params = {
        status: 2,
        pageSize: 50,
        currentPage: 1,
        commodity_type: "社区公开课",
        mechanism_id: this.mechanism_id || null,
        title: typeof query == "string" ? query : null,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.course_list = res.data.data.rows;
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>