<template>
  <div>
    <el-form :model="appointmentForm" ref="aform" label-width="auto">
        <el-form-item label="课程类型">
          <el-radio-group v-model="course_type">
            <el-radio label="mechanism_offline">海马课包</el-radio>
            <!-- <el-radio label="need">需求课程</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="机构城市" >
             <el-cascader
            clearable
            v-model="queryCity"
            :options="cityoptions"
            :props="querycityProps"
            placeholder=""
          ></el-cascader>
           <el-checkbox v-model="parent_mec">主店</el-checkbox>
            </el-form-item>
        <div v-if="course_type == 'mechanism_offline'">
          <el-form-item label="选择机构" required>
            <el-select
              v-model="appointmentForm.mechanism_id"
              placeholder="请输入名称"
              prefix-icon="el-icon-search"
              filterable
              remote
              :remote-method="getMechanism"
              @change="changeMechanism"
            >
              <el-option
                v-for="(item, index) in mechanism_list"
                :key="index"
                :label="item.mechanism_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择课程" required key="offline">
            <el-select
              remote
              :remote-method="getCourse"
              @focus="getCourse"
              v-model="course"
              value-key="id"
              filterable
              placeholder="请输入课程名称"
              prefix-icon="el-icon-search"
              @change="changeCourse"
            >
              <el-option
                v-for="(item, index) in course_list"
                :key="index"
                :label="item.title"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="course_type == 'need'">
          <el-form-item label="上课教练">
            <coach-select :coachid.sync="changeCoach_id"></coach-select>
          </el-form-item>
          <el-form-item label="上课用户">
            <user-select :userid.sync="changeUser_id"></user-select>
          </el-form-item>
          <el-form-item label="选择课程" key="need" required>
            <el-select
              remote
              :remote-method="getCourse"
              @focus="getCourse"
              v-model="course"
              value-key="id"
              filterable
              placeholder="请输入课程名称"
              prefix-icon="el-icon-search"
              @change="changeCourse"
            >
              <el-option
                v-for="(item, index) in course_list"
                :key="index"
                :label="item.title"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="月份" required>
          <el-date-picker
            v-model="value1"
            @change="selectMonth"
            type="month"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="第几周" required>
          <el-select
            v-model="appointmentForm.week_num"
          >
            <el-option
              :label="'第' + i + '周'"
              :value="i"
              v-for="(i, index) in Math.ceil(
                getDateCount(appointmentForm.months_num) / 7
              )"
              :key="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排课节数">
          <el-input v-model="course_num"></el-input>节
        </el-form-item>
        <!-- <el-form-item label="课间休息时长">
          <el-input v-model="time"></el-input>分钟
        </el-form-item> -->
        <el-form-item label="排课时间" required>
          从
          <el-time-select
            placeholder="开始排课"
            v-model="startTime"
            :picker-options="{
              start: '06:00',
              step: '00:05',
              end: '23:00',
            }"
          >
          </el-time-select>
          开始排课
        </el-form-item>
        <el-form-item label="日期选择">
          <el-checkbox-group v-model="week_day" @change="currentWeek">
            <el-checkbox
              v-for="(i, index) in date_count1(
                appointmentForm.week_num,
                appointmentForm.months_num
              )"
              :key="index"
              :label="(appointmentForm.week_num - 1) * 7 + i"
            >
              {{ (appointmentForm.week_num - 1) * 7 + i }}号
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="">
         <el-button
           :loading="loading"
          type="primary"
          @click="newAppointCourse()"
          >添 加 课 程</el-button>
          </el-form-item>
        
      </el-form>
         
  </div>
</template>

<script>
export default {
  name: 'WorkspaceJsonAppointment',

  data() {
    return {
      loading:false,
      course_type:'mechanism_offline',
      appointmentForm:{
         mechanism_id: "",
        months_num: new Date().getMonth() + 1,
        week_num: Math.ceil(new Date().getDate() / 7),
      },
      mechanism_list:[],
      course_list:[],
      couInfo:{},
      changeCoach_id:'',
      changeUser_id:'',
      course:{},
      value1:new Date(),
      course_num:'',
      time:'',
      startTime:'',
      week_day:[],
      queryCity:[],
      cityoptions: JSON.parse(localStorage.getItem("addrList")),
      querycityProps: {
        checkStrictly: true,
        value: "district",
        label: "district",
        children: "childrens",
      },
      parent_mec:false
    };
  },

  mounted() {
     
  },

  methods: {
    resetData(){
         this.couInfo = {};
          this.startTime = ''
          this.course = {}
          this.appointmentForm.list = ''
          this.week_day = []
          this.course_num = ''
          this.time = ''
    },
    // 新增预约课表
    newAppointCourse() {
      if (
        this.course_type != "need" &&
        this.appointmentForm.mechanism_id == ""
      ) {
        this.$message({ message: "请选择机构" });
        return;
      }
      if (this.couInfo == "") {
        this.$message({ message: "请选择课程" });
        return;
      }
      if (this.startTime == "") {
        this.$message({ message: "请选择时间" });
        return;
      }
      this.currentWeek(this.week_day);
      let url = "/user/mechanismSchedule/insertCourseScheduleList";
      let data = { ...this.appointmentForm };
      data.type = "上课";
      if (this.course_type == "need") {
        url = "/user/coachSchedule/batchInsertSchedule";
        data.coach_id = this.couInfo.coach_id;
        data.mechanism_id = null;
        data.type = 1;
      }
      this.loading = true
      this.$axios.post(url, data).then((res) => {
         this.$message({ type: "success", message: res.data.message });
         this.resetData()
            this.loading = false
      }).catch(()=>{
           this.loading = false
      })
    },
    currentWeek(v) {
      let week_day;
      this.appointmentForm.list = [];
      v.forEach((item) => {
        let date = new Date(
          new Date().getFullYear(),
          this.appointmentForm.months_num - 1,
          item
        );
        week_day = date.getDay();
        let start = date.Format("yyyy-MM-dd") + " " + this.startTime + ":00";
        let end = new Date(
          new Date(start).getTime() +
            this.course_num * this.couInfo.length_of_lesson * 60 * 1000 +
            (this.course_num - 1) * this.time * 60 * 1000
        ).Format("yyyy-MM-dd hh:mm:ss");
        this.appointmentForm.list.push({
          start: start,
          end: end,
          is_expensive: false,
          week_day: week_day == 0 ? 7 : week_day,
          time: this.time || 0,
        });
      });
    },
      date_count1(week_num, months_num) {
      if (week_num <= Math.floor(this.getDateCount(months_num) / 7)) {
        return 7;
      } else {
        return this.getDateCount(months_num) % 7;
      }
    },
     currentWeek(v) {
      let week_day;
      this.appointmentForm.list = [];
      v.forEach((item) => {
        let date = new Date(
          new Date().getFullYear(),
          this.appointmentForm.months_num - 1,
          item
        );
        week_day = date.getDay();
        let start = date.Format("yyyy-MM-dd") + " " + this.startTime + ":00";
        let end = new Date(
          new Date(start).getTime() +
            this.course_num * this.couInfo.length_of_lesson * 60 * 1000 +
            (this.course_num - 1) * this.time * 60 * 1000
        ).Format("yyyy-MM-dd hh:mm:ss");
        this.appointmentForm.list.push({
          start: start,
          end: end,
          is_expensive: false,
          week_day: week_day == 0 ? 7 : week_day,
          time: this.time || 0,
        });
      });
    },
     getDateCount(month) {
      if (month) {
        return new Date(new Date().getFullYear(), month, 0).getDate();
      } else {
        return null;
      }
    },
     selectMonth(e) {
      this.appointmentForm.months_num = e.getMonth() + 1;
      this.appointmentForm.week_num = 1;
    },
    changeCourse(v) {
      this.couInfo = v;
      this.appointmentForm.id = v.id;
      this.appointmentForm.mechanism_id = v.mechanism_id
    },
    getMechanism(query) {
      let url = "/user/mastermechanism/queryByMessage";
      if (typeof query == "string") {
        let data = {
              type: "teach_paypal",
              status: 2,
              pageSize: 30,
              currentPage: 1,
              mechanism_name: query,
            }
         if(this.queryCity.length > 0){
          data.province = this.queryCity[0] || null
          data.city = this.queryCity[1] || null
          data.district = this.queryCity[2] || null
        }
        if(this.parent_mec){
          data.parent_id = 0
        }
        this.$axios
          .get(url, {
            params: data
          })
          .then((res) => {
            // console.log(res.data);
            this.mechanism_list = res.data.data.rows;
          })
          .catch((rej) => {});
      }
    },
     changeMechanism(v) {
      this.getCourse();
    },
    getCourse(query) {
      let url;
      let params;
      if (this.course_type == "need") {
        url = "/user/userCourseNeed/queryManagerListPage";
        params = {
          pageSize: 50,
          coach_id:this.changeCoach_id || null,
          currentPage: 1,
          appointment_type : 1,
          title: typeof query == "string" ? query : null,
          mechanism_id: this.appointmentForm.mechanism_id || null
        };
        
      } else {
        url = "/user/masterSetPrice/queryByMessage";
        params = {
          type: "mechanism_offline",
          status: 2,
          pageSize: 50,
          currentPage: 1,
          appointment_type: "appointment",
          mechanism_id:
            this.appointmentForm.mechanism_id || null,
          title: typeof query == "string" ? query : null,
        };
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.course_list = res.data.data.rows;
        });
      // }
    },
  },
};
</script>