<template>
  <div>
    <el-form :model="fixedForm">
        <el-form-item label="课程类型">
          <el-radio-group v-model="course_type">
            <el-radio label="mechanism_offline">海马课包</el-radio>
            <el-radio label="need">需求课程</el-radio>
          </el-radio-group>
        </el-form-item>
         <el-form-item label="排课方式">
          <el-radio-group v-model="fixed_type">
            <el-radio label="user">选择用户</el-radio>
            <el-radio label="class">选择班级</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="course_type == 'mechanism_offline'">
          <el-form-item label="机构城市" >
             <el-cascader
            clearable
            v-model="queryCity"
            :options="cityoptions"
            :props="querycityProps"
            placeholder=""
          ></el-cascader>
           <el-checkbox v-model="parent_mec">主店</el-checkbox>
            </el-form-item>
       <el-form-item label="选择机构" required>
            <el-select
              v-model="fixedForm.mechanism_id"
              placeholder="请输入名称"
              prefix-icon="el-icon-search"
              filterable
              remote
              :remote-method="getMechanism"
              @change="changeMechanism"
            >
              <el-option
                v-for="(item, index) in mechanism_list"
                :key="index"
                :label="item.mechanism_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择课程">
            <el-select
              v-model="course"
              remote
              :remote-method="getCourse"
              clearable
              filterable
              value-key="id"
              @change="getCount"
            >
              <el-option
                v-for="item in course_list"
                :key="item.id"
                :value="item"
                :label="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择班级" v-if="fixed_type == 'class'">
            <el-select
              v-model="change_class_id"
              remote
              :remote-method="getMechanismClass"
              placeholder="选择班级"
               @focus.once="getMechanismClass"
              filterable
              clearable
              prefix-icon="el-icon-search"
            >
              <el-option
                v-for="(item, index) in classList"
                :key="index"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="course_type == 'need'">
          <el-form-item label="选择教练">
            <coach-select :coachid.sync="changeCoach_id"></coach-select>
          </el-form-item>
          
          <el-form-item label="选择课程" v-if="fixed_type == 'user'">
            <el-select
              key="fixn"
              v-model="course"
              remote
              :remote-method="getCourse"
              clearable
              filterable
              @focus="getCourse"
              value-key="id"
              @change="getCount"
            >
              <el-option
                v-for="item in course_list"
                :key="item.id"
                :value="item"
                :label="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>

           <el-form-item label="选择班级" v-if="fixed_type == 'class'">
            <el-select
              v-model="change_class_id"
              remote
              :remote-method="getMechanismClass"
              placeholder="选择班级"
              filterable
              clearable
              @focus.once="getMechanismClass"
              prefix-icon="el-icon-search"
            >
              <el-option
                v-for="(item, index) in classList"
                :key="index"
                :value="item.id"
                :label="item.name"
              >
              <div>
                <span>{{ item.name }}</span>
                <span>-{{item.course_name || ''}}</span>
              </div>
              </el-option>
            </el-select>
          </el-form-item>

        </div>
        <el-form-item label="选择用户" v-if="fixed_type == 'user'">
          <div style="width: 80%; float: left">
            <el-table
            ref="stable"
              :data="user_list"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                prop="id"
              ></el-table-column>
              <el-table-column label="ID" prop="id"></el-table-column>
              <el-table-column
                label="用户昵称"
                prop="map.userinfo.nick_name"
              ></el-table-column>
              <el-table-column
                label="用户手机"
                prop="map.userinfo.mobile"
              ></el-table-column>
            </el-table>
            <el-pagination
              :total="user_total"
              :current-page="currentFixedPage"
              @current-change="changeCurrentPage"
            >
            </el-pagination>
          </div>
        </el-form-item>
        <el-form-item label="上课时间段">
          <el-time-select
            placeholder="起始时间"
            v-model="startTime"
            value-format="hh:mm:ss"
            :picker-options="{ start: '07:00', step: '00:05', end: '23:00' }"
            @change="changeStart"
          >
          </el-time-select>
          -
          <el-time-select
            placeholder="结束时间"
            v-model="endTime"
            :picker-options="{
              start: '07:00',
              step: '00:05',
              end: '23:00',
              minTime: startTime,
            }"
            value-format="hh:mm:ss"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="排课方式">
          <el-radio v-model="fixedForm.type" label="week">星期</el-radio>
          <!-- <el-radio v-model="fixedForm.type" label="calendar">日历</el-radio> -->
        </el-form-item>
        <!-- <el-form-item label="是否重复">
          <el-checkbox v-model="fixedForm.is_repeat"></el-checkbox>
        </el-form-item> -->
        <el-form-item label="选择日期" v-if="fixedForm.type == 'calendar'">
          <calendar2 @selected="getSelectedDate"></calendar2>
        </el-form-item>

        <el-form-item label="选择星期" v-if="fixedForm.type == 'week'">
          <el-checkbox-group v-model="week_day" @change="gudingCurrentWeek">
            <el-checkbox v-for="(i, index) in weeks" :key="index" :label="i">
              {{ i }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="日期范围">
          <el-date-picker
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            v-model="dateRange"
             :picker-options="pickerOptions"
             value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="">
         <el-button
          :loading="loading"
          type="primary"
          @click="addNewFiexed()"
          >添 加 课 程</el-button>
          </el-form-item>
      </el-form>

  </div>
</template>

<script>
import calendar2 from "@/components/calendar2.vue";
export default {
  name: 'WorkspaceJsonFixedAppointment',
components:{
calendar2
},
  data() {
    return {
      loading:false,
      course_type:'mechanism_offline',
      fixed_type : 'user',
      fixedForm:{
        type:'week'
      },
      mechanism_list:[],
      course_list:[],
       queryCity:[],
      cityoptions: JSON.parse(localStorage.getItem("addrList")),
      querycityProps: {
        checkStrictly: true,
        value: "district",
        label: "district",
        children: "childrens",
      },
      classList:[],
      change_class_id:'',
      changeCoach_id:'',
      week_day:[],
      dateRange:[],
      currentFixedPage:1,
      endTime:'',
      startTime:'',
      course:{},
      user_list:[],
      user_total:0,
       weeks: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
        pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 86400000;
        },
      },
       parent_mec:false
    };
  },

  mounted() {
    
  },

  methods: {
    resetData(){
          this.dateRange = []
          this.startTime = ''
          this.endTime = ''
          this.change_class_id = ''
          this.fixedForm.userStudyCard_ids = ''
          this.$refs.stable.clearSelection()
          this.course = {}
          this.fixedForm.master_set_price_id = ''
          this.user_list = []
    },
    changeStart(v){
      if(this.course.length_of_lesson){
let end = new Date(`2020/01/01 ${v}:00`).getTime() + this.course.length_of_lesson * 60000
				this.endTime = new Date(end).Format('hh:mm')
      }
				
    },
    changeCurrentPage(v) {
      this.currentFixedPage = v;
      this.getUserList();
    },
     // 新增固定课表
    addNewFiexed() {
      let url = "/user/mechanismClasses/insertMechanismStudentCourses";
      let data = { ...this.fixedForm };
      if(this.dateRange.length == 0){
        this.$message('请选择排课时间')
        return
      }

      data.start_times = this.startTime ? this.startTime + ":00" : null;
      data.end_times = this.endTime ? this.endTime + ":00" : null;
      data.start_date = this.dateRange[0] + " 00:00:00"
      data.date = this.dateRange[1] + " 23:59:59"
      data.is_qualified = true;
      data.is_repeat = true;
      data.course_type = 1;
      if(this.fixed_type == 'class'){
        data.id = this.change_class_id
      }
      // 需求课程
      if (this.course_type == "need") {
        data.course_type = 2;
      }
      this.loading = true
      this.$axios.post(url, data).then((res) => {
         this.loading = false
         this.$message({ type: "success", message: res.data.data });
         this.resetData()
      }).catch(()=>{
          this.loading = false
      })
    },
     gudingCurrentWeek(v) {
      this.fixedForm.weekOfDays = v.join(",");
    },
      getSelectedDate(v) {
      this.fixedForm.weekOfDays = v.join(",");
    },
     handleSelectionChange(v) {
      let arr = [];
      v.forEach((item) => {
        arr.push(item.id);
      });
      this.fixedForm.userStudyCard_ids = arr.join(",");
    },
     getMechanismClass(){
        let url = "/user/mechanismClasses/queryManagerListPage";

        let param = {}
          if(this.course_type == 'need'){
              param = {
                pageSize: 10,
                currentPage: 1,
                status: 2,
                coach_id: this.changeCoach_id
              }
          }else{
              param =  {
                pageSize: 10,
                currentPage: 1,
                status: 2,
                master_set_price_id:this.fixedForm.master_set_price_id,
                mechanism_id:this.fixedForm.mechanism_id,
              }
          }

      this.$axios
        .get(url, {
          params:param
        })
        .then((res) => {
          let result = res.data.data.rows
          result.forEach(item=>{
            item.name = `${item.name}(${item.student_count}人)`
          })
          this.classList = result;
        })
    },
     getUserList() {
      let url = "/user/userStudyCard/queryStudentInfo";
      this.status = "loading";
      let params = {
        pageSize: 10,
        currentPage: this.currentFixedPage,
        studycard_id: this.fixedForm.master_set_price_id,
        status: 2,
        mechanism_class_id:0,
        mechanism_id: this.fixedForm.mechanism_id || null,
      };
      if (this.course_type == "need") {
        params.coach_id = this.changeCoach_id || null
        params.type = 'need'
      }

      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          let arr = res.data.data.rows.filter((item) => {
            return item.course_num > 0;
          });
          this.user_list = arr;
          // this.studycard_list = [...this.studycard_list, arr]
          this.user_total = res.data.data.total;
        });
    },
     getCount(v) {
      this.fixedForm.count = v.course_num;
      this.fixedForm.master_set_price_id = v.id
      if(this.fixed_type == 'class'){
          this.getMechanismClass()
      }else{
      this.getUserList();

      }
    },
     changeMechanism(v) {
      this.getCourse();
    },
     getMechanism(query) {
      let url = "/user/mastermechanism/queryByMessage";
      if (typeof query == "string") {
        let data = {
              type: "teach_paypal",
              status: 2,
              pageSize: 30,
              currentPage: 1,
              mechanism_name: query,
            }
         if(this.queryCity.length > 0){
          data.province = this.queryCity[0] || null
          data.city = this.queryCity[1] || null
          data.district = this.queryCity[2] || null
        }
        if(this.parent_mec){
          data.parent_id = 0
        }
        this.$axios
          .get(url, {
            params: data
          })
          .then((res) => {
            // console.log(res.data);
            this.mechanism_list = res.data.data.rows;
          })
          .catch((rej) => {});
      }
    },
     getCourse(query) {
      let url;
      let params;
      if (this.course_type == "need") {
        url = "/user/userCourseNeed/queryManagerListPage";
        params = {
          pageSize: 50,
          coach_id:this.changeCoach_id || null,
          currentPage: 1,
          appointment_type : 2,
          title: typeof query == "string" ? query : null,
          mechanism_id: this.fixedForm.mechanism_id || null
        };
        
      } else {
        url = "/user/masterSetPrice/queryByMessage";
        params = {
          type: "mechanism_offline",
          status: 2,
          pageSize: 50,
          currentPage: 1,
          appointment_type: "fixed_scheduling",
          mechanism_id:
            this.fixedForm.mechanism_id || null,
          title: typeof query == "string" ? query : null,
        };
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.course_list = res.data.data.rows;
        });
      // }
    },
  },
};
</script>