<template>
  <div class="container">
     <content-title :nav="nav"></content-title>
     <div>
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="预约排课" name="appoint">
                <appointment/>
            </el-tab-pane>
            <el-tab-pane label="固定排课" name="fixed">
               <fixedAppointment/>
            </el-tab-pane>
            <el-tab-pane label="课包第一节排课" name="firstCourse">
                
                  <firstAppointment/>
            </el-tab-pane>
        </el-tabs>
     </div>
  </div>
</template>

<script>
import appointment from './components/appointment/appointment.vue';
import firstAppointment from './components/appointment/firstAppointment.vue';
import fixedAppointment from './components/appointment/fixedAppointment.vue';

export default {
  components:{
appointment,
firstAppointment,
fixedAppointment
  },
  data() {
    return {
      nav: { firstNav: "课程管理中心", secondNav: "创建课表" },
      activeName:'appoint'
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>